.liz__services {
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    justify-content: space-between;

    gap: 15%;
}


.liz__services-heading h1 {
    font-size: 34px;
    font-family: var(--font-family);
    font-weight: 800;
    line-height: 45px;
}

.liz__serivces-content {
    text-align: left;

}


.liz__serivces-content p {
    font-family: var(--font-family);

    font-size: 18px;
    line-height: 30px;

    color: var(--color-text);
    
}

.liz__serivces-content h3 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #FF8A71;
    margin-top: 2rem;
}

@media  screen  and (max-width: 950px){ 
    .liz__services {
        flex-direction: column;
    }

}