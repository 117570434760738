.liz__about {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    width: 100%;

}

.liz__about-heading h1 {
    font-size: 34px;
    font-family: var(--font-family);
    font-weight: 800;
    line-height: 45px;
}

.liz__about-heading {
    display: flex;
    flex-direction: row;
    text-align: right;
    justify-content: center;
    align-items: flex-end;
    margin-right: 5rem;
}


.liz__about-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.liz__about-content p {
    font-family: var(--font-family);

    font-size: 21px;
    line-height: 30px;

    color: var(--color-text);
    margin-bottom: 2rem;
}