.liz {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    padding-top: 40px;

}

.liz h1 {
    font-size: 34px;
    font-family: var(--font-family);
    font-weight: 800;
    line-height: 45px;
    text-align: center;
}
.liz__brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    gap: 30px;
}

.liz__brand dic {
    flex: 1;
    max-width: 150px;
    min-width: 120px;
    margin: 1rem;
    
    display: flex;
    justify-content: center;
    align-items: center;
}

.liz__brand-img {
    height: 70px;
    width: 130px;
}

.liz__brand-img-deepL {
    height: 90px;
    width: 260px;

    border-radius: 6%;
}

.liz__brand-img-langC {
    height: 70px;
    width: 260px;
}

.liz__brand-img-azure {
    height: 140px;
    width: 160px;
}
.liz__brand-img-hug {
    height: 70px;
    width: 240px;
    border-radius: 5%;
}


@media  screen  and (max-width: 450px){
    .liz__brand {
        display: flex;
        flex-direction: column;
    }

}