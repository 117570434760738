.liz__contact {
    display: flex;

}
.liz__contact-content {
    flex: 1;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    
}
.liz__contact-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    text-align: justify;
    font-size: 62px;
    line-height: 75px;

    letter-spacing: -0.04em;
}
.liz__contact-content p {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-text);
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.liz__contact-content__form {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
    width: 100%;
    height: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.form_button-div {
    display: flex;
    color: var(--gradient-text);
    justify-content: center;
    align-items: center;
}

.liz__contact-content__form-content button {
    flex: 0.6;
    width: 40%;
    max-width: 230px;
    min-height: 50px;

    background: #FF4820;
    border-radius: 5px;

    font-family: var(--font-family);
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: var(--gradient-text);
    cursor: pointer;
    outline: none;

    margin-top: 1.5rem;

    padding: 0 0.5rem;
    border: none;
}
.liz__contact-content__form-field-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1rem;
}

.liz__contact-content__form-field-content-label {
    display: flex;
    width: 100%;

}
.liz__contact-content__form-field-content-label h4 {
    position: relative;
    left: 0;

}
.liz__contact-content__form-field-content-label label {
    text-align: start;
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 27px;
    color: var(--color-text);
}
.liz__contact-content__form-field-content-input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    
    border: noen;
    padding: 0 1rem;
    outline: none;
    color: #fff;
    border-radius: 5px;
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 27px;
    color: #3D6184;
    margin-top: 0.5rem;
}
.liz__contact-content__form-field-content-input input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;
    border-radius: 5px;
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 27px;
    color: #3D6184;
}
.liz__contact-content fieldset {
    outline: none;
    border: 0;
}
.liz__contact-content__form-field-content-input textarea {
    width: 100%;
    height: 60px;
    resize: vertical;
    text-align: center;
}
@media screen and (max-width: 1050px) {
    .liz__contact h1 {
        font-size: 48px;
        line-height: 60px;
    }
    .liz__contact p {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 650px) {
    .liz__contact h1 {
        font-size: 48px;
        line-height: 60px;
    }
    .liz__contact p {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .liz__contact h1 {
        font-size: 36px;
        line-height: 48px;
    }
    .liz__contact p {
        font-size: 14px;
        line-height: 24px;
    }
}