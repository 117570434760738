.liz__footer {
    
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: var(--color-footer);
}

.liz__footer-heading {
    width: 100%;
    text-align: center;

    margin-bottom: 3rem;
}

.liz__footer-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    text-align: center;
    letter-spacing: -0.04em;
}
.liz__footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1rem;
    border: 1px solid #fff;
    text-align: center;
    margin-bottom: 10rem;
    cursor: pointer;
}
.liz__footer-btn p {
    box-sizing: border-box;
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 21px;
    word-spacing: 2px;

}

.liz__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;

    width: 100%;
    text-align: left;
}
.liz__footer-links div {
    width: 250px;
    margin: 1rem;
}

.liz__footer-links_logo {
    display: flex;
    flex-direction: column;
}

.liz__footer-links_logo img {
    width: 71px;
    height: 60px;
    margin-bottom: 1rem;
}

.liz__footer-links_logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    color: #fff;
}
.liz__footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
.liz__footer-links_div h4 {
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    margin-bottom: 1rem;
}

.liz__footer-links_div p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    color: #fff;
    margin-bottom: 0.5rem 0;
    cursor: pointer;
}

.liz__footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}
.liz__footer-copyright p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    color: #fff;
}
@media screen  and (max-width: 850px) {
    .liz__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
    
}
@media screen  and (max-width: 550px) {
    .liz__footer-heading h1 {
        font-size: 32px;
        line-height: 42px;
    }
    .liz__footer-links div {
        margin:  1rem 0;
    }
    .liz__footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }
}
@media screen  and (max-width: 450px) {
    .liz__footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
    
}