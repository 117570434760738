* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;

}

body {
    background: var(--color-bg);
}

a {
    color: unset;
    text-decoration: none;

}

.gradient__bg {
    
        background: -moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

        background: -webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

        background: -o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

        background: -ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

        background: radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    
}

.gradient__text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clop: text;
    -webkit-text-fill-color: transparent;
}
.section__padding {
    padding:  4rem 6rem;
}

@media screen and (max-width: 700px) {
    .section__padding {
        padding:  4rem;
    }
    .section__margin {
        padding:  4rem;
    }
}
@media screen and (max-width: 550px) {
    .section__padding {
        padding:  4rem 2rem;
    }
    .section__margin {
        padding:  4rem;
    }
}