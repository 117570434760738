.liz__header {
    display: flex;
    
}
.liz__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
}

.liz__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;

    font-size: 62px;
    line-height: 75px;

    letter-spacing: -0.04em;

}
.liz__header-content p {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-text);
    margin-top: 1.5rem;

}

.liz__header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;

}

.liz__header-content__input input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;
    border-radius:  5px 0 0 5px;
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 27px;
    color: #3D6184;
}
.liz__header-content__input button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;

    background: #FF4820;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    font-family: var(--font-family);
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: #fff;
    cursor: pointer;
    outline: none;
    padding: 0 1rem;
    border: none;

}

@media screen and (max-width: 1050px) {
    .liz__header {
        flex-direction: column;
    }
    .liz__header-content {
        margin: 0 0 3rem;
    }
    
}
@media screen and (max-width: 650px) {
    .liz__header h1 {
        font-size: 48px;
        line-height: 60px;
    }
    .liz__header p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .liz__header-content__input input, 
    .liz__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
}
@media screen and (max-width: 490px) {
    .liz__header h1 {
        font-size: 36px;
        line-height: 48px;
    }
    .liz__header p {
        font-size: 14px;
        line-height: 24px;
    }
    .liz__header-content__input input, 
    .liz__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }

}